<template>
  <div class="index">
    <!-- 长丰59 丰景的宣传片 -->
    <video
      :autoplay="true"
      controls
      class="video-item"
      v-if="farmID === 59 && mapType == 'video'"
    >
      <source
        :src="$imgOnlineUrl + '/index/videos/fj_mp4.mp4'"
        type="video/mp4"
      />
    </video>
    <iframe
      class="iframe"
      v-show="farmID === 63 && mapType == 'vr'"
      :src="iframeSrc"
    ></iframe>
    <Map v-show="mapType == '2d'"></Map>
    <CesiumMap v-if="mapType == '3d'"></CesiumMap>
    <SpectrumMap
      v-show="mapType == 'gp'"
      :spectrogramList="spectrogramList"
    ></SpectrumMap>
    <div class="mapNav">
      <div
        :class="['btn', 'btn2d', mapType == '2d' ? 'active' : '']"
        @click="changeMap('2d')"
      >
        <span class="type-title">地图</span>
      </div>
      <div
        v-if="farmID === 63"
        :class="['btn', 'btn2d', mapType == 'vr' ? 'active' : '']"
        @click="changeMap('vr')"
      >
        <span class="type-title">VR</span>
      </div>
      <div
        v-if="farmID === 59"
        :class="['btn', 'btn2d', mapType == 'video' ? 'active' : '']"
        @click="changeMap('video')"
      >
        <span class="type-title">宣传片</span>
      </div>
      <!-- <div
        :class="['btn', 'btn3d', mapType == '3d' ? 'active' : '']"
        @click="changeMap('3d')"
      >
        <span class="type-title">地球</span>
      </div> -->
      <div
        v-if="spectrogramList.length"
        :class="['btn', 'btngp', mapType == 'gp' ? 'active' : '']"
        @click="changeMap('gp')"
      >
        <span class="type-title">多光谱</span>
      </div>
    </div>
    <MyNav ref="MyNav"></MyNav>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getFarmInfoApi } from "@/request/api";

export default {
  components: {
    Map: () => import("@/components/Map.vue"),
    CesiumMap: () => import("@/components/CesiumMap.vue"),
    MyNav: () => import("@/components/MyNav.vue"),
    SpectrumMap: () => import("@/components/SpectrumMap.vue"),
  },
  data() {
    return {
      iframeSrc: "",
      setTime1: null,
      spectrogramList: [],
      mapType: "2d",
    };
  },
  beforeDestroy() {
    if (this.setTime1) {
      clearTimeout(this.setTime1);
    }
    this.setTime1 = null;
  },
  computed: {
    ...mapState({
      farmID: (state) => state.user.farmID,
      farmInfo: (state) => state.user.farmInfo,
    }),
  },
  created() {
    this.iframeSrc = this.farmInfo.vr_page;
    this.mapType = this.farmInfo.vr_page && this.farmID === 63 ? "vr" : "2d";
    this.getFarmInfoApi();
  },

  methods: {
    changeMap(type) {
      this.mapType = type;
    },
    async getFarmInfoApi() {
      const { code, results } = await getFarmInfoApi({
        manor_id: this.farmID,
      });
      if (code !== 0) return;
      if (!results.spectrogram) return;
      let spectrogram = JSON.parse(results.spectrogram);
      for (const key in spectrogram) {
        let obj = {};
        obj.img = key;
        obj.tip = spectrogram[key];
        this.spectrogramList.push(obj);
      }
    },
  },
};
</script>
<style lang="less" scoped>
video::-webkit-media-controls-fullscreen-button {
  display: block !important;
}
.index {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
  .iframe,
  .video-item {
    width: 100vw;
    height: 100vh;
  }
  //   .video-item {
  //   width: 100vw;
  //   position: absolute;
  //   z-index: 1998;
  //   bottom: 120px;
  //   left: 78px;
  // }
  .mapNav {
    position: absolute;
    bottom: 80px;
    right: 80px;
    z-index: 9999;
    display: flex;

    .btn {
      width: 88px;
      height: 58px;
      margin-left: 20px;
      position: relative;
      background: url("../assets/image/maptype.png") 0 0 no-repeat;
      cursor: pointer;
      background-size: 86px 240px;
      border: 2px solid #fff;

      .type-title {
        display: inline-block;
        min-width: 50%;
        min-height: 16px;

        line-height: 16px;
        padding: 0 4px;
        color: #333;
        border-radius: 0 16px 16px 0;
        font-size: 12px;
        background-color: #fff;
      }
      &.active {
        border-color: aquamarine;
      }
    }
    .btn2d {
      background-position: 0 0;
    }
    .btn3d {
      background-position: 0 -181px;
    }
    .btngp {
      background: url("../assets/image/map_gp.jpg") 0 0 no-repeat;
      background-size: cover;
    }
  }
}
</style>
